<template>
  <div class="customer">
    <div class="breadcrumb">{{ $t("customer") }}</div>
    <div class="customers">
      <tool-bar
        @search="handleSearch"
        @change-view="handleChangeView"
        :current="currentView"
      >
        <template v-slot:other>
          <n-button
            v-if="displayAssign"
            @click="handleAssign"
            :disable="disable"
          >
            <!-- 分配给业务员 -->
            {{ $t("assign") }}
            <seller-list
              :visible.sync="showSells"
              :sells="sells"
              @click="handelSelectSell"
            >
            </seller-list>
          </n-button>
          <n-button
            v-if="displaySendBackBtn"
            @click="handleSendBack"
            :disable="disable"
          >
            <!-- 放回公海客户 -->
            {{ $t("return") }}
          </n-button>
          <n-button
            v-if="displayReceive"
            @click="handleReceive"
            :disable="disable"
          >
            <!-- 转为我的客户 -->
            {{ $t("makeMine") }}
          </n-button>
          <n-button v-if="displaySaveBtn" @click="handleSave">
            {{ $t("save") }}
          </n-button>
          <n-button v-if="displayEditBtn" @click="handleEdit">
            {{ $t("edit") }}
          </n-button>
        </template>

        <template v-slot:create><span v-if="!isMyCustomer"></span></template>
      </tool-bar>
      <div class="customer-view">
        <!-- <a-spin tip="Loading..." :spinning="loading"> -->
        <a-empty v-if="empty" />
        <div class="card-view" v-if="cardView">
          <div class="customer-cards">
            <customer-card
              v-for="customer in customers"
              :key="customer.id"
              :customer="customer"
              @click="handleClickCard"
              @edit="handleCardEdit"
            ></customer-card>
          </div>
          <a-pagination
            class="pagination"
            size="small"
            @change="handleCardPageChange"
            v-model="pagination.page"
            show-less-items
            :pageSize="pagination.pageSize"
            :total="pagination.total"
          />
        </div>
        <n-table
          v-if="tableView"
          :loading="loading"
          :data="customers"
          :columns="columns"
          :selectedRow="ids"
          :pagination="pagination"
          @change="handleTableChange"
          @select="handleSelectCustomer"
        >
        </n-table>
        <div class="detail-view p-100" v-if="isCreateCustomer">
          <customer-detail
            @close="handleClose"
            :customer="record"
            :edit="edit"
			@submit="handleSave"
            @change="handleChange"
          ></customer-detail>
        </div>
        <!-- </a-spin> -->
      </div>
    </div>
  </div>
</template>

<script>
import CustomerCard from "@/components/card/CustomerCard.vue";
import ToolBar from "../tool-bar/index.vue";
import NTable from "../table/index.vue";
import CustomerDetail from "../customer-detail/index.vue";
import NButton from "../tool-bar/button/index.vue";
import SellerList from "../seller-list/index.vue";
import api from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("jiyang/user");
export default {
  data() {
    return {
      search: "",
      showSells: false,
      sells: [],
      seller: null,
      currentView: "tableView",
      loading: false,
      customers: [],
      ids: [],
      url: "",
	  isCreateCustomer:false,
      record: {
        last_name: "",
        first_name: "",
        enterprise: "",
        address: "",
        street: "",
        street2: "",
        city: "",
        province: "",
        country: "",
        tel: "",
        user_mobile: "",
        user_email: "",
        site: "",
        tax: "",
        tag: "",
        vat_number: "",
        head_img: "",
      },
      edit: false,
      update: false,
      user: {
        role: "admin",
        info: {},
      },
      pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
        current: 1,
      },
      columns: [
        {
          title: this.$t("company"),
          dataIndex: "enterprise",
          key: "enterprise",
          width: "240px",
        },
        {
          title: this.$t("contact"),
          dataIndex: "first_name",
          customRender: (text, row) => {
            return <span>{row && row.last_name + text}</span>;
          },
          width: "140px",
        },
        {
          title: this.$t("mobile"),
          dataIndex: "user_mobile",
          key: "user_mobile",
          width: "160px",
        },
        {
          title: this.$t("email"),
          dataIndex: "user_email",
          key: "user_email",
          width: "180px",
        },

        {
          title: this.$t("creator"),
          dataIndex: "create_user_name",
          key: "create_user_name",
          width: "100px",
        },
        {
          title: this.$t("status"),
          dataIndex: "status",
          key: "status",
          width: "180px",
		  customRender: (text, row) => {
		    return row.user_id>0?'有账号':'无账号';
		  },
        },
        /* {
          title: this.$t("operate"),
          // dataIndex: "create_user_name",
          key: "operate",
          width: "200px",
          customRender: (text, row) => {
            return (
              <div>
				<i
				  class="el-icon-document-copy"
				  style="margin-right:20px;font-size:20px"
				  onClick={(e) => this.handleToggleView(row)}
				/>
              </div>
            );
          },
        }, */
      ],
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.init(val);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["info"]),
    displayAssign() {
      return (
        this.isAdmin &&
        this.$route.query.menu == "publicCustomer" &&
        this.record.enterprise.trim() == ""
      );
    },
    isAdmin() {
      return this.user.role == "admin";
    },
    isSeller() {
      return this.user.role == "seller";
    },
    disable() {
      return this.ids.length == 0;
    },
    empty() {
      return this.customers.length == 0 && this.currentView == "cardView";
    },
    createView() {
      return this.currentView === "createView";
    },
    cardView() {
      return this.currentView === "cardView" && this.customers.length > 0;
    },
    tableView() {
      return this.currentView === "tableView";
    },
    detailView() {
      return this.currentView === "detailView";
    },
    displaySendBackBtn() {
      return this.$route.query.menu == "myCustomer";
    },
    displayReceive() {
      return this.$route.query.menu == "publicCustomer" && this.isSeller;
    },
    displaySaveBtn() {
      return (
        (this.currentView === "detailView" && this.edit) ||
        this.currentView == "createView"
      );
    },
    displayEditBtn() {
      return this.currentView === "detailView" && !this.edit;
    },
    isMyCustomer() {
      return this.$route.query.menu == "myCustomer";
    },
  },
  created() {
    this.getCookie();
    this.init();
	if(this.$route.query.user_email){
		this.isCreateCustomer = true;
		this.edit = true;
		this.record = this.$route.query;
	}
  },
  methods: {
    handleClose() {
      this.currentView = "tableView";
	  this.isCreateCustomer = false;
    },
    getCookie() {
      let info = this.$cookie.get("user_info");
      this.user.info = JSON.parse(info);
    },
    handleSendBack() {
      this.batch(api.putServiceCustomerToSea, this.getOwnerCustomerList);
      this.ids = [];
    },
    handleSearch(input) {
      this.search = input;
      const query = this.$route.query.menu;
      switch (query) {
        case "publicCustomer":
          return;
        case "companyCustomer":
          this.searchCustomer();
          break;
        case "myCustomer":
          return;
        default:
          this.getOwnerCustomerList();
      }
    },
    async searchCustomer() {
      this.loading = true;
      this.customers = await api.getCustomerSelectList({ name: this.search });
      this.loading = false;
      this.pagination = {
        total: this.customers.length,
        pageSize: 10,
        current: 1,
        page: 1,
      };
    },
    handleSelectCustomer(customer) {
      this.ids = customer;
    },
    async batch(update, get) {
      let leads = this.ids;
      if (!leads || leads.length == 0) {
        return;
      }
      for (let idx = 0; idx < leads.length; idx++) {
        try {
          await update({ id: leads[idx] })
        } catch (e) {
          console.log(e);
        } finally{
          this.loading = false;
          this.ids = [];
        }
      }
      await get();
    },
    async handleAssign() {
      this.loading = true;
      this.sells = await api.getServiceList();
      this.loading = false;
      this.showSells = true;
    },
    handleReceive() {
      let ids = this.ids || [];
      if (ids.length == 0) {
        return;
      }
      this.batch(api.getSeaCustomerToOwner, this.getSeaCustomerList);
    },
    async handelSelectSell(seller) {
      this.seller = seller;
      let ids = this.ids;
      this.showSells = false;
      this.loading = true;
      for (let idx = 0; idx < ids.length; idx++) {
        await api.setSeaCustomerToService({
          customer_id: ids[idx],
          service_id: seller.id,
        });
      }
      this.loading = false;
      this.switchApi();
    },
    getSeaCustomerList() {
      this.useRequest(api.getSeaCustomerList);
    },
    getCustomerList() {
      this.useRequest(api.getCustomerList);
    },
    getOwnerCustomerList() {
      this.useRequest(api.getOwnerCustomerList);
    },
    handleCardEdit(customer) {
      this.currentView = "detailView";
      this.edit = true;
      this.record = customer;
    },
    handleEdit() {
      this.update = true;
      this.edit = true;
    },
    handleChange(customer) {
      this.record = customer;
    },
    init() {
      let type = this.user.info.user_type;
      let roleMap = {
        1: "admin",
        2: "seller",
        3: "seller",
      };
      this.currentView = "tableView";
      this.user.role = roleMap[type];
      this.cleanPageInfo();
      this.cleanRecord();
      this.switchApi();
    },
    switchApi() {
      this.ids = [];
      const { v = "tableView", menu, url, cw } = this.$route.query;
      this.currentView = v;
      if (v == "createView") {
        this.edit = true;
      }
      if (url) {
        this.url = `${url}&v=${cw}`;
      }
      switch (menu) {
        case "publicCustomer":
          this.getSeaCustomerList();
          break;
        case "companyCustomer":
          this.getCustomerList();
          break;
        case "myCustomer":
          this.getOwnerCustomerList();
          break;
        default:
          this.getOwnerCustomerList();
      }
    },
    cleanPageInfo() {
      this.pagination = {
        pageSize: 10,
        total: 0,
        page: 1,
        current: 1,
      };
    },
    cleanRecord() {
      this.record = {
        last_name: "",
        first_name: "",
        enterprise: "",
        address: "",
        street: "",
        street2: "",
        city: "",
        province: "",
        country: "",
        tel: "",
        user_mobile: "",
        head_img: "",
        user_email: "",
        site: "",
        tax: "",
        tag: "",
      };
    },
    handleCardPageChange(page, pageSize) {
      this.pagination = { pageSize, page };
      this.switchApi();
    },
    handleTableChange({ pagination }) {
      this.pagination = pagination;
      this.switchApi();
    },
    async handleSave() {
      if (!this.record.enterprise.trim()) {
        this.$message.error("企业名称不能空");
        return;
      }
      if (!this.record.user_mobile.trim()) {
        this.$message.error("手机号码不能空");
        return;
      }
      this.loading = true;

      const {
        country = "",
        province = "",
        city = "",
        street = "",
      } = this.record;

      this.record.address = `${country}$_${province}$_${city}$_${street}`;

      if (this.update) {
        await api.updateCustomer(this.record).then((res)=>{
			if(res.status == 1){
				this.$message({
				  message: res.msg,
				  type: 'success'
				});
			}else{
				 this.$message.error(res.msg);
			}
		});
      } else {
        await api.createCustomer(this.record).then((res)=>{
			if(res.status == 1){
				this.$message({
				  message: res.msg,
				  type: 'success'
				});
			}else{
				 this.$message.error(res.msg);
			}
		});
      }
      this.edit = false;
      this.update = false;
      this.loading = false;
      this.init();
      if (this.url) {
        this.$router.push({ path: this.url });
      }
      this.currentView = "tableView";
    },
    handleToggleView(record) {
      this.edit = false;
      this.record = record;
      this.currentView = "detailView";
    },
    async useRequest(request) {
      this.loading = true;
      const { total, per_page, current_page, data } = await request(
        this.pagination
      );
      this.customers = data.map((c) => {
        let [country = "", province = "", city = "", street = ""] =
          c.address.split("$_");
        let realAddress = c.address.replace(/\$_/g, "");
        return {
          ...c,
          street,
          city,
          province,
          country,
          realAddress,
        };
      });
      this.loading = false;
      this.pagination = {
        pageSize: per_page,
        total: total,
        page: current_page,
        current: current_page,
      };
    },
    handleChangeView(view) {
      this.currentView = view;
      if (view == "createView") {
		this.isCreateCustomer = true;
        this.edit = true;
        this.cleanRecord();
      }
    },
    handleClickCard(customer) {
      this.edit = false;
      this.currentView = "detailView";
      this.record = customer;
    },
  },
  components: {
    CustomerCard,
    ToolBar,
    NTable,
    CustomerDetail,
    NButton,
    SellerList,
  },
};
</script>

<style lang="scss"></style>
