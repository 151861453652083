<template>
  <div class="customer-card" @click="handleClick">
    <div class="avatar">
      <img
        :src="
          customer.head_img ||
          'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.174267730247144261640329585.425.png'
        "
        alt="avatar"
      />
    </div>
    <div class="customer-info">
      <div class="company">{{ customer.enterprise }}</div>
      <div class="phone">{{ $t("tel") + `:${customer.user_mobile}` }}</div>
      <div class="email">{{ $t("email") + `:${customer.user_email}` }}</div>
      <a-dropdown placement="bottomLeft">
        <a @click="(e) => e.preventDefault()">
          <i class="iconfont icon-shenglvehao operator"></i>
        </a>
        <a-menu slot="overlay" @click="handleMenu">
          <a-menu-item key="1">
            <i class="iconfont icon-edit"></i>编辑</a-menu-item
          >
          <a-menu-item key="2">
            <i class="iconfont icon-delete"></i>删除</a-menu-item
          >
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "customer-card",
  props: {
    customer: {
      type: Object,
      default: () => ({
        head_img: "/image/user.png",
        enterprise: "",
        user_mobile: "",
        user_email: "",
      }),
    },
  },
  methods: {
    handleMenu(menu) {
      switch (menu.key) {
        case "1":
          this.$emit("edit", this.customer);
          break;
        case "2":
          this.$emit("remove", this.customer);
          break;
      }
    },
    handleClick() {
      this.$emit("click", this.customer);
    },
  },
};
</script>

<style lang="scss">
.customer-card {
  width: 424px;
  height: 126px;
  border: 1px solid #b9b9b9;
  background-color: #fff;
  display: flex;
  transform: translateZ(0);
  will-change: transform;
  &:hover {
    border: 1px solid var(--primaryColor);
    cursor: pointer;
    // transform: scale(1.01);
    transition: all linear 0.3s;
    .avatar {
      border-right: 1px solid var(--primaryColor);
      transition: all linear 0.3s;
    }
  }
  .avatar {
    width: 126px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    border-right: 1px solid #b9b9b9;
    img {
      width: 99px;
      object-fit: contain;
    }
  }
  .customer-info {
    color: #656565;
    font-size: 18px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    flex: 1;
    .company {
      font-size: 16px;
      font-weight: 700;
      color: #282828;
      margin-bottom: 8px;
    }
    .phone {
      margin-bottom: 4px;
      font-size: 14px;
    }
    .email {
      font-size: 14px;
    }
    .ant-dropdown-trigger {
      position: absolute;
      right: 8px;
      top: 8px;
    }
    .operator {
      transform: rotate(90deg);
      position: absolute;
      right: -4px;
      padding: 4px 4px;
      color: #656565;
      &:hover {
        color: var(--primaryColor);
      }
    }
  }
}
</style>
