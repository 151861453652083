<template>
  <div class="sell-list" v-if="visible">
    <div class="title">{{ $t("pleaseSelect") }}</div>
    <a-empty v-if="sells.length == 0" />
    <ul class="sells" v-else>
      <li
        class="sell-item"
        v-for="(sell, idx) in sells"
        :key="sell.id || idx"
        @click.stop="handleClick(sell)"
      >
        {{ "" + sell.last_name + sell.first_name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleClick(sell) {
      this.$emit("click", sell);
      this.$emit("update:visible", false);
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    sells: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.sell-list {
  .title {
    padding: 8px 8px;
    font-size: 14px;
  }
  background-color: #fff;
  width: 180px;
  height: 230px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.08);
  .sells {
    padding: 8px 8px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 200px;
    .sell-item {
      padding: 8px 8px;
      cursor: pointer;
      &:hover {
        background-color: var(--primaryColor);
        color: #fff;
      }
    }
  }
}
</style>
